import React from "react"
import Layout from "../components/layout"
import Img from 'gatsby-image'
import SEO from "../components/seo"
import { Link, graphql } from "gatsby"
import truncate from 'truncate-html'

export default ({data}) => {

    return (
      <Layout>
        <SEO title="Clearinghouse Blog Posts"/>
        <main role="main">
            <div className="row">
                    <div className="col-md-12">
                        <h1 className="text-center mb-5">Blog</h1>
                    </div>
                </div>
        </main>
        <section className="bg-grey">
        	<div className="container">
                <div className="row">
                    { data.allNodeArticle.edges.map(({ node }, index) => ( 
                        <div className="col-md-6" key={node.id}> 
                            <div className="card mb-5" key={node.id}> 
                                <Link to={node.path.alias}>
                                     <Img fluid={ node.relationships.field_image.localFile.childImageSharp.fluid } />
                                </Link>         
                                 <div className="card-body">
                                    <Link to={node.path.alias}>
                                        <h4>{ node.title }</h4> 
                                    </Link>         

                                    <p className="card-text mt-4" dangerouslySetInnerHTML={{ __html: truncate(node.body.value, 50, {byWords: true}) }} />
                                    <div className="d-flex justify-content-between align-items-center">
                                        <div className="btn-group">
                                           <Link className="btn btn-sm btn-outline-secondary" to={node.path.alias}>
                                                View
                                            </Link>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div> 
                    ))} 

        		</div>
        	</div>
    	</section>		
      </Layout>
    )
}
export const query = graphql` 
	query {
		allNodeArticle {  
			edges {
				node {
					id 
                    title 
                    body { value format processed summary }
                    created
                    path { alias }
                    relationships {
                        field_image {
                          localFile {
                            childImageSharp {
                              fluid(maxWidth: 400, quality: 100) {
                                ...GatsbyImageSharpFluid
                              }
                            }
                          }
                        }
                    }

				} 
			} 
		}  
	} `

// export default BlogPage
